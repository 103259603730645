import React, {useState} from 'react'
import { ReactTyped } from 'react-typed';
import socialIconsData from '../social.json';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AtSymbolIcon, BeakerIcon } from '@heroicons/react/24/solid'
import { Button, Tooltip } from "flowbite-react";

export default function DiscordWidget() {



  const [isOpen, setIsOpen] = useState(false)


  return (
    <div className="absolute p-4 text-center transform -translate-x-1/2 -translate-y-1/2 border top-1/2 left-1/2 rounded-lg bg-dark bg-opacity-50 backdrop-filter backdrop-color-dark backdrop-blur-lg w-96 h-64 md:w-96 md:h-64 lg:w-192 lg:h-128">
      <iframe
        title="Discord user embed"
        width="100%"
        height="100%"
        frameBorder="0"
        sandbox="allow-scripts"
        src="https://widgets.vendicated.dev/user?id=1121032386478624789&theme=dark&banner=true&full-banner=false&rounded-corners=true&discord-icon=true&badges=true&guess-nitro=true&"
      />
      <div className="absolute bottom-0 left-0 right-0 text-white">
        <div className="flex justify-center mb-4">
            {socialIconsData.map((icon, index) => (
              <a key={index} href={icon.url} target="_blank" rel="noopener noreferrer" className="mx-2">
                <Tooltip content={icon.name} placement="right">
                <button onMouseOver={() => setIsOpen(!isOpen)} onMouseOut={() => setIsOpen(!isOpen)}><AtSymbolIcon className="h-6 w-6"/></button>
                </Tooltip>
              </a>
            ))}
          </div>
        <p className="mb-4 font-mono text-black px-40">{""}<ReactTyped strings={["cute", "gangster", "code", "by", "dope", "<3"]} typeSpeed={100} loop /></p>
      </div>
    </div>
  );
}
