import React, {useState} from 'react'
import Background from './Background';
import DiscordWidget from './DiscordWidget';

const Enter = () => {
    const [entered, setEntered] = useState(false);
  
    const handleEnter = () => {
      setEntered(true);
    };
  
    return (
      <div className="h-screen flex justify-center items-center">
        {!entered ? (
          <button
            onClick={handleEnter}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Click to Enter
          </button>
        ) : (
            <Background />
        )}
      </div>
    );
  };
  

export default Enter;